$(document).on("click", ".calculate", function () {
  progressBarCalculation();
});
function progressBarCalculation(){
  const price1Text = $(".calc-result__price--1 .price-sum").text();
  const price2Text = $(".calc-result__price--2 .price-sum").text();
  const voc1Text = $(".voc-1 .voc-sum").text();
  const voc2Text = $(".voc-2 .voc-sum").text();
  $(".calc-sum").removeClass("h-0");
  const price1 = parseFloat(price1Text.replace(",", "."));
  const price2 = parseFloat(price2Text.replace(",", "."));
  const voc1 = parseFloat(voc1Text.replace(",", "."));
  const voc2 = parseFloat(voc2Text.replace(",", "."));

  if (price2 > price1) {
    $(".calc-result__price--2").css("width", "100%");
    $(".calc-result__price--2").css("background-color", "#E05536");
    $(".calc-result__price--1").css("background", "linear-gradient(90deg, #8CB893 "+(price1 * 100) / price2 + "%, #F2F2F2 0%)");
    $(".calc-result__price--1").css("width", "100%");
  } else if (price1 > price2) {
    $(".calc-result__price--1").css("width", "100%");
    $(".calc-result__price--2").css("background", "linear-gradient(90deg, #8CB893 "+(price2 * 100) / price1 + "%, #F2F2F2 0%)");
    $(".calc-result__price--1").css("background-color", "#E05536");
    $(".calc-result__price--2").css("width", "100%");
  } else {
    $(".calc-result__price--1").css("width", "100%");
    $(".calc-result__price--2").css("width", "100%");
    $(".calc-result__price--1").css("background-color", "#8CB893");
    $(".calc-result__price--2").css("background-color", "#8CB893");
  }
  if (voc2 > voc1) {
    $(".voc-2").css("width", "100%");
    $(".voc-2").css("background-color", "#E05536");
    $(".voc-1").css("background", "linear-gradient(90deg, #8CB893 "+(voc1 * 100) / voc2 + "%, #FFFFFF 0%)");
    $(".voc-1").css("width", "100%");
  } else if (voc1 > voc2) {
    $(".voc-1").css("width", "100%");
    $(".voc-2").css("background", "linear-gradient(90deg, #8CB893 "+(voc2 * 100) / voc1 + "%, #FFFFFF 0%)");
    $(".voc-1").css("background-color", "#E05536");
    $(".voc-2").css("width", "100%");
  } else {
    $(".voc-1").css("width", "100%");
    $(".voc-2").css("width", "100%");
    $(".voc-1").css("background-color", "#8CB893");
    $(".voc-2").css("background-color", "#8CB893");
  }
  $('html, body').animate({
    scrollTop: $("#calc-sum-wrapper").offset().top
 }, 800);
}
$(document).ready(function () {
  $(".column").each(function () {
    var checkedRadio = $(this).find("input[type='radio']:checked");
    if (checkedRadio.length) {
      console.log("Checked radio found in column", $(this));
      checkedRadio.closest(".form-check-label").addClass("checked");
    }
  });

  // Update on change
  $("input[type='radio']").change(function () {
    var closestColumn = $(this).closest(".column");
    closestColumn.find(".form-check-label").removeClass("checked");
    if ($(this).prop("checked")) {
      $(this).closest(".form-check-label").addClass("checked");
    }
  });
});

$(".check-opener").on("click", function (e) {
  event.preventDefault();
  // $(this).preventDefault();
  $(this).next().toggleClass("opened");
});
const swiper = new Swiper(".swiper", {
  loop: true,
  effect: "fade",
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

$(document).ready(function () {
  if ($(window).width() > 992) {
    $(".parallax-desktop").parallax({
      speed: 0.3,
    });
  }else{
    $('nav .position-relative > a').click(function(e){
      e.preventDefault();
      e.stopPropagation();
      $(this).parent().find('.header__popover').toggle();
      $(this).parent().toggleClass('active');
    })
  }
});
$(document).on('click', '.circle', function (e) {
  $(".color-number").text($(this).attr("data-number"));
  $(".color-name").text($(this).attr("data-name"));
  $(".color-info").text($(this).attr("data-number")+ ' ' + $(this).attr("data-name"));

});
// RGB to HEX converter function
function rgbToHex(rgb) {
  const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (!match) {
    return null;
  }

  function hex(x) {
    const hexValue = parseInt(x).toString(16);
    return hexValue.length === 1 ? "0" + hexValue : hexValue;
  }

  return "#" + hex(match[1]) + hex(match[2]) + hex(match[3]);
}

$(document).ready(function () {
  // Update number input based on range input
  $(".range-input, .number-input").val(0);
  $(".range-input").on("input", function () {
    var value = $(this).val();
    var max = $(this).attr("max");
    var percentage = (value / max) * 100;
    console.log(percentage);
    $(this).css("--percentage", percentage + "%");
    $(this)
      .siblings(".background-range")
      .css(
        "background",
        "linear-gradient(90deg, rgb(0, 0, 0)" +
          percentage +
          "%, rgb(221, 221, 221) " +
          percentage +
          "%)"
      );

    // Update the number input based on its proximity to the range input
    $(this).closest(".column").find(".number-input").val(value);
  });

  // Update range input based on number input
  $(".number-input").on("input", function () {
    var value = $(this).val();
    var associatedRange = $(this).closest(".column").find(".range-input");
    var max = associatedRange.attr("max");
    var percentage = (value / max) * 100;
    associatedRange.css("--percentage", percentage + "%");
    associatedRange.val(value);
  });
});
$(document).on("click", ".circle", function (e) {
  let color = $(this).css("background-color");
  let hexColor = rgbToHex(color);

  $(".color-number").text($(this).attr("data-number"));
  $(".color-name").text($(this).attr("data-name"));
  $(".choosen-color").css("backgroundColor", color);
  $(".color-background").css("backgroundColor", color);
  $(".color-match").find(".option").removeClass("selected");

  let targetOption = $(".color-match").find(
    ".option[data-value='" + hexColor.toLowerCase() + "']"
  );
  if (targetOption.length) {
    targetOption.trigger("click");
  }
});
$(document).on("click", ".color-match .option", function (e) {
  let hexColor = $(this).attr("data-value").toLowerCase();

  $(".choosen-color, .color-background").css("backgroundColor", hexColor);

  $(".circle").each(function () {
    if (rgbToHex($(this).css("background-color")).toLowerCase() === hexColor) {
      $(".color-number").text($(this).attr("data-number"));
      $(".color-name").text($(this).attr("data-name"));

      return false;
    }
  });
});

$(document).ready(function () {
  setTimeout(function () {
    // Check if the target node exists
    var target = $(".current")[0];

    if (target) { // Make sure the element exists
      // Create an observer instance with a callback function
      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.type == "childList" || mutation.type == "characterData") {
            var currentText = $(".current").text();
            var firstLiText = $(".list li").first().text();
            if (currentText !== firstLiText) {
              $(".current").css("color", "#212529");
            } else {
              $(".current").css("color", ""); // Or set to its original color
            }
          }
        });
      });

      // Observer configuration with what we want to observe
      var config = { childList: true, characterData: true, subtree: true };

      // Start observing the target node
      observer.observe(target, config);
    }
  }, 3000);
});

$(document).ready(function () {
  if ($(".form-check-color").is(":visible")) {
    let images = $(".color-background");
    let imagesArray = jQuery.makeArray(images);
    $(".form-check-color")
      .find(".form-check-input")
      .click(function () {
        let i = $(this).attr("id");
        $(".color-background").addClass("d-none");
        $(imagesArray[i]).removeClass("d-none");
      });
  }
});
$(window).resize(function () {
  if ($(window).width() > 992) {
    $(".parallax-desktop").parallax({
      speed: 0.3,
    });
  } else {
    $(".parallax-desktop").parallax("destroy");
  }
});
const button = document.querySelectorAll(".my-slick-2 .slick-slide");
let mousedownTime;

$(".my-slick-2 .slick-slide").mousedown(function () {
  mousedownTime = new Date().getTime();
});

var slideId = 0;
$(".my-slick-2 .slick-slide").click(function () {
  slideId = $(this).data('slick-index');
  slider = setInterval(function () {
    if ($(".slick-slide").width() > 100) {
      $(".modal-content").addClass("content-on");
      $(".slider-box").addClass("add-visibility");
      clearInterval(slider);
    }
  }, 400);
});

var myModalEl = document.getElementById('staticBackdro');

if(myModalEl) {
  myModalEl.addEventListener('shown.bs.modal', function (event) {
    $('.my-slick-4').slick('unslick');

    $('.my-slick-4').slick({
      initialSlide: slideId,
      arrows: true,
      slidesToScroll: 1,
      infinite: true,
      focusOnSelect: true,
      prevArrow: $(".slick-arrow-prev1"),
      nextArrow: $(".slick-arrow-next1"),
    })
    $('.my-slick-4').css('opacity', 1);
  })

  myModalEl.addEventListener('hide.bs.modal', function (event) {
    $('.my-slick-4').css({
      'opacity': 0,
      'transition': 'opacity 0.5s ease'
    });
  })
}

$('.my-slick-4').on('afterChange', function(event, slick, currentSlide){
  if($('.my-slick-2')[0].slick.slideCount > $('.my-slick-2')[0].slick.options.slidesToShow) {
    $('.my-slick-2').slick('slickGoTo', currentSlide);
  }
});

$(".my-slick-2 .slick-slide").mouseup(function () {
  const mouseupTime = new Date().getTime();
  timeDifference = mouseupTime - mousedownTime;
  if (timeDifference < 350) {
    $(".modal-slider").modal("show");
  }
});
$(".btn-close-slider").click(function () {
  $(".modal-slider").modal("hide");
});

$(".accordion-button").click(function (e) {
  let text = $(this).is(".collapsed") == true ? "Pokaż pliki" : "Ukryj pliki";
  $(this).children(".accordion-toggler").text(text);
});
$(".header__category").hover(function () {
  $(".header__category").children("div").addClass("d-none");
  $(this).children("div").removeClass("d-none");
});
$(".header__popover").hover(function () {

},function () {
  setTimeout(() => {
    $(".header__category").children("div").addClass("d-none");
    $(".header__category:first-child").children("div").removeClass("d-none");
  }, "1000");
});
$(".accordion-button").click(function () {
  const allCollapsesElementsNotShow = document.querySelectorAll(
    ".accordion-collapse:not(.show)"
  );
  const allCollapsesElements = document.querySelectorAll(".accordion-collapse");
  if (
    allCollapsesElementsNotShow.length == allCollapsesElements.length &&
    $(this).hasClass("collapsed")
  ) {
    $(".accordions-hide").removeClass("d-none");

    $(".accordions-show").addClass("d-none");
  } else {
    $(".accordions-show").removeClass("d-none");

    $(".accordions-hide").addClass("d-none");
  }
});
$(".accordions-toggler").click(function () {
  const collapseElementList = document.querySelectorAll(".collapse.show");
  const allCollapsesElements = document.querySelectorAll(
    ".collapse:not(.show)"
  );
  const collapseList = [...collapseElementList].map(
    (collapseEl) => new bootstrap.Collapse(collapseEl)
  );
  // Jeżeli ilość elementów otwartych jest rowna wszystkim elementom wtedy
  if (collapseElementList.length == 0) {
    const collapseList = [...allCollapsesElements].map(
      (collapseEl) => new bootstrap.Collapse(collapseEl)
    );
    $(".accordions-show").removeClass("d-none");
    $(".accordions-hide").addClass("d-none");
  } else {
    $(".accordions-hide").removeClass("d-none");
    $(".accordions-show").addClass("d-none");
  }
});
$(".hamburger").click(function () {
  $(".header__container").toggleClass("header__container--active");
  $(".hamburger").toggleClass("hamburger--active");
});
$(document).click(function (event) {
  if (
    !$(event.target).is(
      ".hamburger, .hamburger span, input, .dropdown, .dropdown-toggle"
    )
  ) {
    $(".header__container").removeClass("header__container--active");
    $(".hamburger").removeClass("hamburger--active");
  }
});
$(".lost").click(function () {
  $(".login-reset").removeClass("d-none");
  $(".login-hide").addClass("d-none");
});
$(document).ready(function () {
  $("select").niceSelect();
  $(document).on("change", "#linkSelect", function () {
    let selectedLink = $(this).val();
    if (selectedLink !== "") {
      window.location.href = selectedLink;
    }
  });
});

$(".brand").click(function () {
  $(this).toggleClass("active");
});

$(document).ready(function () {
  $(".my-slick").slick({
    infinite: true,
    dots: true,
    arrows: true,
    prevArrow: $(".slick-arrow-prev"),
    nextArrow: $(".slick-arrow-next"),
  });
});
$(".filters").click(function (e) {
  e.stopPropagation();
});
$(document).click(function () {
    $(".filters").removeClass("show");
});
$(".filters__button").click(function () {
  setTimeout(function () {
    $(".filters").addClass("show");
  }, 100);
});
$(".close-filters").click(function () {
  $(".filters").removeClass("show");
});
// $(function () {
//   $('.popover').popover()
// })
$(".circle-wrapper").click(function (event) {
  if ($(event.target).hasClass("active")) {
    var isActive = 1;
  } else {
    var isActive = 0;
  }

  $(this).children(".point-circle").removeClass("active");

  if ($(event.target).hasClass("point-circle") && isActive == 0) {
    $(event.target).addClass("active");
  }
});

function update() {
  const elem = document.getElementById("product");
  const calc = document.querySelector(".calculator__header");
  const item = document.getElementById("app");
  const rect = elem.getBoundingClientRect();
  if (
    rect.bottom > window.innerHeight &&
    rect.top < window.innerHeight &&
    window.innerWidth > 576
  ) {
    calc.classList.add("fixed-vertical");
    calc.style.bottom = "0px";
  } else if (
    rect.bottom > window.innerHeight + 416 &&
    rect.top < window.innerHeight &&
    window.innerWidth < 576
  ) {
    calc.classList.add("fixed-vertical");
    calc.style.bottom = "-1px";
    item.classList.add("product__item--padding");
  } else {
    calc.classList.remove("fixed-vertical");
    calc.style.bottom = "-73px";
    item.classList.remove("product__item--padding");
  }
}
$(document).ready(function () {
  if ($("div").hasClass("calculator__header")) {
    document.addEventListener("scroll", update);
  }
});
var fire = 1;
// update();
function parallaxOnTime() {
  const elem = document.getElementById("product");
  const rect = elem.getBoundingClientRect();
  if (rect.bottom > 647 && rect.top < 0) {
    let height = rect.top / 25;
    document.getElementById("myDIV").style.transform =
      "translateX(" + height + "px)";
  }
}
function productNav() {
  const elem = document.getElementById("product");
  const elem2 = document.getElementById("product2");
  const navElements = document.querySelectorAll(".product__nav-main div");
  const specSection = document.querySelectorAll(".product__item");
  const rect = elem.getBoundingClientRect();
  const rect2 = elem2.getBoundingClientRect();
  const top = 80;

  if (rect2.bottom - top > 0 && rect.top < 0 && window.innerWidth > 992) {
    // let height = rect.top / 25;
    document
      .querySelector(".product__nav-main")
      .classList.add("position-fixed");
    document.querySelector(".product__nav-main").style.width = "100%";
    document.querySelector(".product__nav-main").style.top = "0px";
  } else {
    document
      .querySelector(".product__nav-main")
      .classList.remove("position-fixed");
  }
}

let navItems = document.querySelectorAll(".product__nav-main div a");
let distances = [];

function activeElement() {
  if ($(".product__item").is(":visible")) {
    const productItems = document.querySelectorAll(".product__item");
    let product__item = [...productItems].map(function (index, key) {
      distances[key] = index.getBoundingClientRect();
      if (distances[key].top < 50 && distances[key].bottom > 0) {
        navItems.forEach((element) => {
          element.classList.remove("active");
        });
        navItems[key].classList.add("active");
      }
    });
  }
}

$(document).on("click", ".documentation li", function (e) {
  let documentations = $(".documentation li");
  let documentationsElements = $(".language-doc");
  $(".language-doc").addClass("d-none");
  documentationsElements.eq($(this).data("value")).removeClass("d-none");
  console.log(documentationsElements.eq($(this).data("value")));
});

$(document).ready(function () {
  if ($("div").hasClass("calculator__header")) {
    document.addEventListener("scroll", function () {
      parallaxOnTime();
      productNav();
      activeElement();
    });
  }
});

$(".slider-toggler").click(function () {
  $(".slider-toggler").removeClass("active");
  $(this).addClass("active");
});

$(document).ready(function () {
  const slickSliders = $(".slick-container");
  const mapSliders = slickSliders.map(mySliders);
  const sliderTogglers = $(".slider-toggler");
  const mapTogglers = sliderTogglers.map(myTogglers);
  function myTogglers(index) {
    $(this).addClass("toggler" + index);
  }
  $(".toggler-wrapper").click(function () {
    let index = $(".toggler-wrapper").index(this);
    // if ($(".slick-sliders").hasClass("product-slider" + index)) {
    //   console.log(this);
    // }
    $(".slick-container").addClass("hide");
    $(".slick-container" + index).removeClass("hide");
  });
  function mySliders(index) {
    if ($(this).parent().hasClass("product-sliders")) {
      $(this)
        .children(".slick-sliders")
        .addClass("product-slider" + index);
      $(this).addClass("slick-container" + index);
    }
    $(this)
      .children(".slick-sliders")
      .addClass("my-slick-" + index);
    $(this)
      .children(".slick-arrow-prev")
      .addClass("slick-arrow-prev" + index);
    $(this)
      .children(".slick-arrow-next")
      .addClass("slick-arrow-next" + index);
    $(this)
      .children(".slick-sliders")
      .slick({
        infinite: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: $(".slick-arrow-prev" + index),
        nextArrow: $(".slick-arrow-next" + index),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              autoplay: false,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              autoplay: true,
              autoplaySpeed: 2000,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    $(this)
      .children(".slick.my-slick-2")
      .slick({
        infinite: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        prevArrow: $(".slick-arrow-prev" + index),
        nextArrow: $(".slick-arrow-next" + index),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              autoplay: false,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 2000,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    $(this)
      .children(".slick.my-slick-4")
      .slick({
        infinite: true,
        arrows: true,
        slidesToScroll: 1,
        asNavFor: ".my-slick-2",
        prevArrow: $(".slick-arrow-prev" + index),
        nextArrow: $(".slick-arrow-next" + index),
      });
    $(this)
        .children(".slick.my-slick-5")
        .slick({
          infinite: true,
          arrows: true,
          slidesToShow: 5,
          slidesToScroll: 5,
          prevArrow: $(".slick-arrow-prev" + index),
          nextArrow: $(".slick-arrow-next" + index),
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                autoplay: false,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                autoplay: true,
                autoplaySpeed: 2000,
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 375,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
  }
});

var shapesParallax = document.getElementsByClassName("shapesparallax");
new simpleParallax(shapesParallax, {
  delay: 1,
  transition: ".35,.13,.59,.82",
  scale: 2.8,
});



$(document).ready(function () {
  $(".map__image").removeClass("active");
  if ($("input[id='flexRadioDefault1']").is(":checked")) {
    $(".map__image--profix").addClass("active");
  } else {
    $(".map__image--simplex").addClass("active");
  }
});
$(".map__changer").click(function () {
  $(".map__image").removeClass("active");
});
$(".map__changer--blue").click(function () {
  $(".map__image--profix").addClass("active");
});
$(".map__changer--yellow").click(function () {
  $(".map__image--simplex").addClass("active");
});
var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (
    prevScrollpos < currentScrollPos &&
    currentScrollPos > $(".banner__top").height()
  ) {
    $("#navbar").addClass("hide");
    $(".header__container").removeClass("header__container--active");
    $(".hamburger").removeClass("hamburger--active");
   // $(".filters").removeClass("show");
  } else {
    $("#navbar").removeClass("hide");
  }
  prevScrollpos = currentScrollPos;
};

var sParallax = document.getElementsByClassName("sparallax");
new simpleParallax(sParallax, {
  delay: 0.6,
  transition: ".35,.13,.59,.82",
  scale: 1.15,
});

var scaleUp = document.getElementsByClassName("sparallax-scale-up");
new simpleParallax(scaleUp, {
  delay: 0.8,
  transition: ".35,.13,.59,.82",
  scale: 1.3,
});

var scene = document.getElementById("scene");

(function ($) {
  $.fn.niceSelect = function (method) {
    // Methods
    if (typeof method == "string") {
      if (method == "update") {
        this.each(function () {
          var $select = $(this);
          var $dropdown = $(this).next(".nice-select");
          var open = $dropdown.hasClass("open");

          if ($dropdown.length) {
            $dropdown.remove();
            create_nice_select($select);

            if (open) {
              $select.next().trigger("click");
            }
          }
        });
      } else if (method == "destroy") {
        this.each(function () {
          var $select = $(this);
          var $dropdown = $(this).next(".nice-select");

          if ($dropdown.length) {
            $dropdown.remove();
            $select.css("display", "");
          }
        });
        if ($(".nice-select").length == 0) {
          $(document).off(".nice_select");
        }
      } else {
        // console.log('Method "' + method + '" does not exist.');
      }
      return this;
    }

    // Hide native select
    this.hide();

    // Create custom markup
    this.each(function () {
      var $select = $(this);

      if (!$select.next().hasClass("nice-select")) {
        create_nice_select($select);
      }
    });

    function create_nice_select($select) {
      $select.after(
        $("<div></div>")
          .addClass("nice-select")
          .addClass($select.attr("class") || "")
          .addClass($select.attr("disabled") ? "disabled" : "")
          .addClass($select.attr("multiple") ? "has-multiple" : "")
          .attr("tabindex", $select.attr("disabled") ? null : "0")
          .html(
            $select.attr("multiple")
              ? '<span class="multiple-options"></span><div class="nice-select-search-box"><input type="text" class="nice-select-search" placeholder="Szukaj"/></div><ul class="list"></ul>'
              : '<span class="current"></span><div class="nice-select-search-box"><input type="text" class="nice-select-search" placeholder="Szukaj"/></div><ul class="list"></ul>'
          )
      );

      var $dropdown = $select.next();
      var $options = $select.find("option");
      if ($select.attr("multiple")) {
        var $selected = $select.find("option:selected");
        var $selected_html = "";
        $selected.each(function () {
          $selected_option = $(this);
          $selected_text =
            $selected_option.data("display") || $selected_option.text();

          if (!$selected_option.val()) {
            return;
          }

          $selected_html +=
            '<span class="current">' + $selected_text + "</span>";
        });
        $select_placeholder =
          $select.data("js-placeholder") || $select.attr("js-placeholder");
        $select_placeholder = !$select_placeholder
          ? "Select"
          : $select_placeholder;
        $selected_html =
          $selected_html === "" ? $select_placeholder : $selected_html;
        $dropdown.find(".multiple-options").html($selected_html);
      } else {
        var $selected = $select.find("option:selected");
        $dropdown
          .find(".current")
          .html($selected.data("display") || $selected.text());
      }

      $options.each(function (i) {
        var $option = $(this);
        var display = $option.data("display");

        $dropdown.find("ul").append(
          $("<li></li>")
            .attr("data-value", $option.val())
            .attr("data-display", display || null)
            .addClass(
              "option" +
                ($option.is(":selected") ? " selected" : "") +
                ($option.is(":disabled") ? " disabled" : "")
            )
            .html($option.text())
        );
      });
    }

    /* Event listeners */

    // Unbind existing events in case that the plugin has been initialized before
    $(document).off(".nice_select");

    // Open/close
    $(document).on("click.nice_select", ".nice-select", function (event) {
      var $dropdown = $(this);

      $(".nice-select").not($dropdown).removeClass("open");
      $dropdown.toggleClass("open");

      if ($dropdown.hasClass("open")) {
        $dropdown.find(".option");
        $dropdown.find(".nice-select-search").val("");
        $dropdown.find(".nice-select-search").focus();
        $dropdown.find(".focus").removeClass("focus");
        $dropdown.find(".selected").addClass("focus");
        $dropdown.find("ul li").show();
      } else {
        $dropdown.focus();
      }
    });

    $(document).on("click", ".nice-select-search-box", function (event) {
      event.stopPropagation();
      return false;
    });
    $(document).on("keyup.nice-select-search", ".nice-select", function () {
      var $self = $(this);
      var $text = $self.find(".nice-select-search").val();
      var $options = $self.find("ul li");
      if ($text == "") $options.show();
      else if ($self.hasClass("open")) {
        $text = $text.toLowerCase();
        var $matchReg = new RegExp($text);
        if (0 < $options.length) {
          $options.each(function () {
            var $this = $(this);
            var $optionText = $this.text().toLowerCase();
            var $matchCheck = $matchReg.test($optionText);
            $matchCheck ? $this.show() : $this.hide();
          });
        } else {
          $options.show();
        }
      }
      $self.find(".option"),
        $self.find(".focus").removeClass("focus"),
        $self.find(".selected").addClass("focus");
    });

    // Close when clicking outside
    $(document).on("click.nice_select", function (event) {
      if ($(event.target).closest(".nice-select").length === 0) {
        $(".nice-select").removeClass("open").find(".option");
      }
    });

    // Option click
    $(document).on(
      "click.nice_select",
      ".nice-select .option:not(.disabled)",
      function (event) {
        var $option = $(this);
        var $dropdown = $option.closest(".nice-select");
        if ($dropdown.hasClass("has-multiple")) {
          if ($option.hasClass("selected")) {
            $option.removeClass("selected");
          } else {
            $option.addClass("selected");
          }
          $selected_html = "";
          $selected_values = [];
          $dropdown.find(".selected").each(function () {
            $selected_option = $(this);
            var attrValue = $selected_option.data("value");
            var text =
              $selected_option.data("display") || $selected_option.text();
            $selected_html += `<span class="current" data-id=${attrValue}> ${text} <span class="remove">X</span></span>`;
            $selected_values.push($selected_option.data("value"));
          });
          $select_placeholder =
            $dropdown.prev("select").data("js-placeholder") ||
            $dropdown.prev("select").attr("js-placeholder");
          $select_placeholder = !$select_placeholder
            ? "Select"
            : $select_placeholder;
          $selected_html =
            $selected_html === "" ? $select_placeholder : $selected_html;
          $dropdown.find(".multiple-options").html($selected_html);
          $dropdown.prev("select").val($selected_values).trigger("change");
        } else {
          $dropdown.find(".selected").removeClass("selected");
          $option.addClass("selected");
          var text = $option.data("display") || $option.text();
          $dropdown.find(".current").text(text);
          $dropdown.prev("select").val($option.data("value")).trigger("change");
        }
        // console.log($(".searchSelect").val());
      }
    );
    //---------remove item
    $(document).on("click", ".remove", function () {
      var $dropdown = $(this).parents(".nice-select");
      var clickedId = $(this).parent().data("id");
      $dropdown.find(".list li").each(function (index, item) {
        if (clickedId == $(item).attr("data-value")) {
          $(item).removeClass("selected");
        }
      });
      $selected_values.forEach(function (item, index, object) {
        if (item === clickedId) {
          object.splice(index, 1);
        }
      });
      $(this).parent().remove();
      // console.log($(".searchSelect").val());
    });

    // Keyboard events
    $(document).on("keydown.nice_select", ".nice-select", function (event) {
      var $dropdown = $(this);
      var $focused_option = $(
        $dropdown.find(".focus") || $dropdown.find(".list .option.selected")
      );

      // Space or Enter
      if (event.keyCode == 32 || event.keyCode == 13) {
        if ($dropdown.hasClass("open")) {
          $focused_option.trigger("click");
        } else {
          $dropdown.trigger("click");
        }
        return false;
        // Down
      } else if (event.keyCode == 40) {
        if (!$dropdown.hasClass("open")) {
          $dropdown.trigger("click");
        } else {
          var $next = $focused_option.nextAll(".option:not(.disabled)").first();
          if ($next.length > 0) {
            $dropdown.find(".focus").removeClass("focus");
            $next.addClass("focus");
          }
        }
        return false;
        // Up
      } else if (event.keyCode == 38) {
        if (!$dropdown.hasClass("open")) {
          $dropdown.trigger("click");
        } else {
          var $prev = $focused_option.prevAll(".option:not(.disabled)").first();
          if ($prev.length > 0) {
            $dropdown.find(".focus").removeClass("focus");
            $prev.addClass("focus");
          }
        }
        return false;
        // Esc
      } else if (event.keyCode == 27) {
        if ($dropdown.hasClass("open")) {
          $dropdown.trigger("click");
        }
        // Tab
      } else if (event.keyCode == 9) {
        if ($dropdown.hasClass("open")) {
          return false;
        }
      }
    });

    // Detect CSS pointer-events support, for IE <= 10. From Modernizr.
    var style = document.createElement("a").style;
    style.cssText = "pointer-events:auto";
    if (style.pointerEvents !== "auto") {
      $("html").addClass("no-csspointerevents");
    }

    return this;
  };
})(jQuery);
